<template>
    <div class="guests">
        <v-row style="max-width: 2000px" class="mx-auto">
            <v-col cols="12">

                <!-- <v-btn class="ml-4 overline" color="teal" dark @click="onClickOverview">
                    <v-icon dense class="mr-1">mdi-view-dashboard</v-icon>
                    Обзор
                </v-btn> -->

                <v-btn class="ml-4 overline" color="blue darken-3" dark @click="onClickByOrders">
                    <v-icon dense class="mr-1">mdi-inbox-outline</v-icon>
                    По заказам
                </v-btn>

                <v-btn class="ml-4 overline" color="purple darken-3" dark @click="onClickByRate">
                    <v-icon dense class="mr-1">mdi-star-outline</v-icon>
                    По рейтингу
                </v-btn>
            </v-col>


            <v-col cols="12" class="pt-0">
                
                <guests-overview v-if="mode == 0" :data="guestsOverviewData"></guests-overview>
                <guests-orders v-if="mode == 1"></guests-orders>
                <guests-rating v-if="mode == 2" :data="guestsRatingData"></guests-rating>
            </v-col>

        </v-row>

        <span class="pb-16 d-block" />

    </div>
</template>

<script>
    import config from '../../config'

    import GuestsOverview from './GuestsOverview'
    import GuestsRating from './GuestsRating'
    import GuestsOrders from './GuestsOrders'

    export default {
        data() {
            return {
                config,
                mode: 1,
                guestsRatingData: {},
                guestsOverviewData: {},
            }
        },
        components: {
            GuestsOverview,
            GuestsRating,
            GuestsOrders,
        },
        mounted() {
            this.guestsRatingData.search = this.$route.params.phone

            if (this.guestsRatingData.search) {
                this.mode = 2
                window.history.replaceState({}, document.title, window.location.origin + '/orders')
            }
        },

        methods: {

            onClickOverview() {
                this.mode = 0
                this.guestsRatingData.search = ''
            },

            onClickByOrders() {
                this.mode = 1
                this.guestsRatingData.search = ''
            },

            onClickByRate() {
                this.mode = 2
                this.guestsRatingData.search = ''
            },

        },
    }
</script>

<style lang="stylus">
    .guest-hidden
        color #90A4AE !important

    .rowItemSuccess1
        background #E0F2F1 !important

    .rowItemSuccess2
        background #B2DFDB !important

    .rowItemWarning
        background #FFF3E0 !important

    .rowItemDisable
        cursor default !important
        background #fff !important

        [role="button"]
            cursor default !important

    .guests
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            // background inherit !important
            cursor default
            .v-data-table__wrapper > table > thead > tr
                th
                    white-space nowrap
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                &:hover
                    filter: brightness(97%);
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px
            &:hover
                cursor pointer

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor pointer
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline
                font-weight 500
            
</style>
