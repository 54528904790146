<template>

    <div>

        <p class="mt-4 text-h5">👋🤖 Скоро тут будет обзор по гостям</p>
        

    </div>

</template>

<script>
    import config from '../../config'

    export default {
        data() {
            return {
            }
        },
        props: {
            data: {
                type: Object,
            },
        },
        mounted() {
            console.log('guests overview start!')
        },

        methods: {

            
        },
    }
</script>
